import '../scss/main.scss';

// Swiper
import { Swiper } from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

// Fancybox
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

Swiper.use([Navigation, Autoplay]);

const sliders = document.querySelectorAll('.pt-person-swiper, .pt-client-swiper') as NodeListOf<HTMLDivElement>;

Array.from(sliders).forEach((slider) => {
    const slug = slider.dataset.slug || '';
    const slidesPerView = slider.dataset.slug === 'client' ? 4 : 2;
    const slidesPerView768 = slider.dataset.slug === 'client' ? 5 : 3;
    const slidesPerView1024 = slider.dataset.slug === 'client' ? 6 : 4;
    const shouldAutoPlay = slider.dataset.slug === 'client';

    const options: any = {
        autoHeight: true,
        direction: 'horizontal',
        loop: true,
        slidesPerView,
        spaceBetween: 15,
        breakpoints: {
            768: {
                slidesPerView: slidesPerView768,
                spaceBetween: 30,
            },
            1024: {
                slidesPerView: slidesPerView1024,
                spaceBetween: 50,
            },
        },
        navigation: {
            nextEl: `.swiper-button-next-${slug}`,
            prevEl: `.swiper-button-prev-${slug}`,
        },
    };

    if (shouldAutoPlay) {
        options.autoplay = {
            delay: 1000,
        };
    }

    /* eslint-disable no-new */
    new Swiper(slider as HTMLElement, options);
});

Fancybox.bind('[data-fancybox]', {
    defaultDisplay: 'flex',
});
